

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap');
// @import url('../bootstrap-icons/bootstrap-icons.css');

.config-section{    
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.left-box{
    width: 100%;
    padding: 10px 40px;
}

@media (max-width: 700px){
    .left-box{        
        padding: 15px 30px 10px 20px;
    }
}

.config-tabs > .nav-item > .nav-link:hover{
    color: #5e82a3 !important;
}