

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap');
// @import url('../bootstrap-icons/bootstrap-icons.css');

:root {
    --legend-first: rgba(75, 192, 192, 0.4);
    --legend-second: rgba(54, 162, 235, 0.4);
    --legend-third: rgba(153, 102, 255, 0.4);
    --legend-fourth: rgba(255, 99, 132, 0.4);
    --bs-table-striped-bg: #fafafa;
}


.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.home--container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: flex-start;
    gap: 20px;
    padding: 50px 30px;
}

.chart{    
    max-width: 70%;    
}


.tabela{    
    max-width: 40%;
    width: 600px;
}

.legenda{
    text-align: center;
    font-weight: 600;
    color: rgb(116, 119, 119);
    margin-top: 35px;
    min-width: 80px;
}

.legenda > ul{
    display: flex;
    align-content: center;
    gap: 13px;
    padding-left: 25px;
}

.legenda li{
    margin-bottom: 6px;
    font-size: 80%;
    list-style: none;
}

.legenda li > span{
    display: block;
    float: left;
    height: 15px;
    width: 55px;    
}

.legenda li:first-child > span{background-color: var(--legend-first);}
.legenda li:nth-child(2n) > span{background-color: var(--legend-second);}
.legenda li:nth-child(3n) > span{background-color: var(--legend-third);}
.legenda li:last-child > span{background-color: var(--legend-fourth);}

.color1{color: var(--legend-first) !important}
.color2{color: var(--legend-second)!important}
.color3{color: var(--legend-third) !important}
.color4{color: var(--legend-fourth)!important}


.legenda > div{
    font-size: 0.6rem;
    text-align: left;
    margin-left: 1.5rem;
}

@media all and (max-width: 600px){ 
    .home--container{
        padding: 20px 10px;
    }
    .chart, .tabela{    
        max-width: 100%;
        margin-bottom: 20px;
    }    
}


