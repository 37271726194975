.navbar-prodabit {
    background-color: #34587a !important;
    border-bottom: 1px solid #dc3545!important;
    color: #fff;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 7px 20px;
}

.navbar-prodabit > .menu-hamburguer{
    border-color: #fff;
    font-size: 2rem;
    margin: 0 1rem 0 -4px;
}

.navbar-prodabit > .menu-hamburguer:hover{
    cursor: pointer;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

.navbar-prodabit h5 {
    color: #fff !important;
    font-size: 1.1rem;
    margin: 0;
}

.navbar{
    padding: 0.44rem 1.25rem;
}

.navbar-prodabit{
    background-color: #34587a !important;
    border-bottom: 1px solid #dc3545!important;
} 
  
    
.navbar-brand {
    font-size: 1rem;
}
  
.navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
}

.border-right {
    border-right: 1px solid #dee2e6!important;
}

.info-user{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    background-color: #f8f9fa;
    color: #5e5f5d;
    padding: 7px 20px 3px;
    border-bottom: 1px solid #f09164;   
    font-size: 0.7rem; 
}

.info-user > div {
    margin-bottom: 1px;
    min-width: 40%;
}

.select--procard > .form-select-sm{
    display: inline-block !important;
    font-size: 0.8rem !important;
    background-color: transparent;
    width: 164px;
    margin-left: 5px;
    padding: 0px;
    border: none;      
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
}


@media (max-width: 450px) {
    .info-user{                
        font-size: 0.8rem; 
    }

    .info-user > div{                
        margin-bottom: 5px;
        max-width: 95%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
  }

