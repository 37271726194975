body {
  font-size: .875rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}



#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
}


/*
 * Content
 */

[role="main"] {
  padding-top: 133px; /* Space for fixed navbar */
}

@media (min-width: 768px) {
  [role="main"] {
    padding-top: 48px; /* Space for fixed navbar */
  }
}


.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.container {
  max-width: 960px;
}

.lh-condensed { line-height: 1.25; }

.my-legend .legend-title {
  text-align: center;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 90%;
  }
.my-legend .legend-scale ul {
  list-style: none;    
  padding: 0;
  }
.my-legend .legend-scale ul li {
  display: block;
  float: left;
  width: 50px;
  margin-bottom: 6px;
  text-align: center;
  font-size: 80%;
  list-style: none;
  }
.my-legend ul.legend-labels li span {
  display: block;
  float: left;
  height: 15px;
  width: 50px;
  }
.my-legend .legend-source {
  font-size: 70%;
  color: #999;
  clear: both;
  }
.my-legend a {
  color: #777;
}
.my-legend .btn-warning {
  color: #212529;
}

/*.my-td-category{
  height: 10px;  
} */ 

.my-td-category{
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  margin-top: 2px;
}  


  form .form-control::-moz-placeholder {
    color: #d7dbde;
    opacity: 1;
  }
  form .form-control:-ms-input-placeholder {
    color: #d7dbde;
  }
  form .form-control::-webkit-input-placeholder {
    color: #d7dbde;
  }

  .sr-only, .bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after, .bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after, .bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after, .bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after, .bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after, .bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after, .bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after, .bootstrap-datetimepicker-widget .btn[data-action="clear"]::after, .bootstrap-datetimepicker-widget .btn[data-action="today"]::after, .bootstrap-datetimepicker-widget .picker-switch::after, .bootstrap-datetimepicker-widget table th.prev::after, .bootstrap-datetimepicker-widget table th.next::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0; }
  
  .bootstrap-datetimepicker-widget {
    list-style: none; }
    .bootstrap-datetimepicker-widget.dropdown-menu {
      display: block;
      margin: 2px 0;
      padding: 4px;
      width: 14rem; }
      @media (min-width: 576px) {
        .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
          width: 38em; } }
      @media (min-width: 768px) {
        .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
          width: 38em; } }
      @media (min-width: 992px) {
        .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
          width: 38em; } }
      .bootstrap-datetimepicker-widget.dropdown-menu:before, .bootstrap-datetimepicker-widget.dropdown-menu:after {
        content: '';
        display: inline-block;
        position: absolute; }
      .bootstrap-datetimepicker-widget.dropdown-menu.bottom:before {
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #ccc;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        top: -7px;
        left: 7px; }
      .bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid white;
        top: -6px;
        left: 8px; }
      .bootstrap-datetimepicker-widget.dropdown-menu.top:before {
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid #ccc;
        border-top-color: rgba(0, 0, 0, 0.2);
        bottom: -7px;
        left: 6px; }
      .bootstrap-datetimepicker-widget.dropdown-menu.top:after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid white;
        bottom: -6px;
        left: 7px; }
      .bootstrap-datetimepicker-widget.dropdown-menu.float-right:before {
        left: auto;
        right: 6px; }
      .bootstrap-datetimepicker-widget.dropdown-menu.float-right:after {
        left: auto;
        right: 7px; }
    .bootstrap-datetimepicker-widget .list-unstyled {
      margin: 0; }
    .bootstrap-datetimepicker-widget a[data-action] {
      padding: 6px 0; }
    .bootstrap-datetimepicker-widget a[data-action]:active {
      box-shadow: none; }
    .bootstrap-datetimepicker-widget .timepicker-hour, .bootstrap-datetimepicker-widget .timepicker-minute, .bootstrap-datetimepicker-widget .timepicker-second {
      width: 54px;
      font-weight: bold;
      font-size: 1.2em;
      margin: 0; }
    .bootstrap-datetimepicker-widget button[data-action] {
      padding: 6px; }
    .bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after {
      content: "Increment Hours"; }
    .bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
      content: "Increment Minutes"; }
    .bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after {
      content: "Decrement Hours"; }
    .bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
      content: "Decrement Minutes"; }
    .bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
      content: "Show Hours"; }
    .bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
      content: "Show Minutes"; }
    .bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
      content: "Toggle AM/PM"; }
    .bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
      content: "Clear the picker"; }
    .bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
      content: "Set the date to today"; }
    .bootstrap-datetimepicker-widget .picker-switch {
      text-align: center; }
      .bootstrap-datetimepicker-widget .picker-switch::after {
        content: "Toggle Date and Time Screens"; }
      .bootstrap-datetimepicker-widget .picker-switch td {
        padding: 0;
        margin: 0;
        height: auto;
        width: auto;
        line-height: inherit; }
        .bootstrap-datetimepicker-widget .picker-switch td span {
          line-height: 2.5;
          height: 2.5em;
          width: 100%; }
    .bootstrap-datetimepicker-widget table {
      width: 100%;
      margin: 0; }
      .bootstrap-datetimepicker-widget table td,
      .bootstrap-datetimepicker-widget table th {
        text-align: center;
        border-radius: 0.25rem; }
      .bootstrap-datetimepicker-widget table th {
        height: 20px;
        line-height: 20px;
        width: 20px; }
        .bootstrap-datetimepicker-widget table th.picker-switch {
          width: 145px; }
        .bootstrap-datetimepicker-widget table th.disabled, .bootstrap-datetimepicker-widget table th.disabled:hover {
          background: none;
          color: #868e96;
          cursor: not-allowed; }
        .bootstrap-datetimepicker-widget table th.prev::after {
          content: "Previous Month"; }
        .bootstrap-datetimepicker-widget table th.next::after {
          content: "Next Month"; }
      .bootstrap-datetimepicker-widget table thead tr:first-child th {
        cursor: pointer; }
        .bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
          background: #e9ecef; }
      .bootstrap-datetimepicker-widget table td {
        height: 54px;
        line-height: 54px;
        width: 54px; }
        .bootstrap-datetimepicker-widget table td.cw {
          font-size: .8em;
          height: 20px;
          line-height: 20px;
          color: #868e96; }
        .bootstrap-datetimepicker-widget table td.day {
          height: 20px;
          line-height: 20px;
          width: 20px; }
        .bootstrap-datetimepicker-widget table td.day:hover, .bootstrap-datetimepicker-widget table td.hour:hover, .bootstrap-datetimepicker-widget table td.minute:hover, .bootstrap-datetimepicker-widget table td.second:hover {
          background: #e9ecef;
          cursor: pointer; }
        .bootstrap-datetimepicker-widget table td.old, .bootstrap-datetimepicker-widget table td.new {
          color: #868e96; }
        .bootstrap-datetimepicker-widget table td.today {
          position: relative; }
          .bootstrap-datetimepicker-widget table td.today:before {
            content: '';
            display: inline-block;
            border: solid transparent;
            border-width: 0 0 7px 7px;
            border-bottom-color: #007bff;
            border-top-color: rgba(0, 0, 0, 0.2);
            position: absolute;
            bottom: 4px;
            right: 4px; }
        .bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
          background-color: #007bff;
          color: #fff;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }
        .bootstrap-datetimepicker-widget table td.active.today:before {
          border-bottom-color: #fff; }
        .bootstrap-datetimepicker-widget table td.disabled, .bootstrap-datetimepicker-widget table td.disabled:hover {
          background: none;
          color: #868e96;
          cursor: not-allowed; }
        .bootstrap-datetimepicker-widget table td span {
          display: inline-block;
          width: 54px;
          height: 54px;
          line-height: 54px;
          margin: 2px 1.5px;
          cursor: pointer;
          border-radius: 0.25rem; }
          .bootstrap-datetimepicker-widget table td span:hover {
            background: #e9ecef; }
          .bootstrap-datetimepicker-widget table td span.active {
            background-color: #007bff;
            color: #fff;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }
          .bootstrap-datetimepicker-widget table td span.old {
            color: #868e96; }
          .bootstrap-datetimepicker-widget table td span.disabled, .bootstrap-datetimepicker-widget table td span.disabled:hover {
            background: none;
            color: #868e96;
            cursor: not-allowed; }
    .bootstrap-datetimepicker-widget.usetwentyfour td.hour {
      height: 27px;
      line-height: 27px; }
  
  .input-group.date .input-group-append {
    cursor: pointer; }  



  .multi-steps > li.is-active:before, .multi-steps > li.is-active ~ li:before {
    content: counter(stepNum);
    font-family: inherit;
    font-weight: 700;
  }
  .multi-steps > li.is-active:after, .multi-steps > li.is-active ~ li:after {
    background-color: #ededed;
  }
  
  .multi-steps {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .multi-steps > li {
    counter-increment: stepNum;
    text-align: center;
    display: table-cell;
    position: relative;
    color: #17a2b8;
    z-index: 0;
  }
  .multi-steps > li:before {
    content: '\f00c';
    content: '\2713;';
    content: '\10003';
    content: '\10004';
    content: '\2713';
    display: block;
    margin: 0 auto 4px;
    background-color: #fff;
    width: 36px;
    height: 36px;
    line-height: 32px;
    text-align: center;
    font-weight: bold;
    border-width: 2px;
    border-style: solid;
    border-color: #17a2b8;
    border-radius: 50%;
  }
  .multi-steps > li:after {
    content: '';
    height: 2px;
    width: 100%;
    background-color: #17a2b8;
    position: absolute;
    top: 16px;
    left: 50%;
    z-index: -1;
  }
  .multi-steps > li:last-child:after {
    display: none;
  }
  .multi-steps > li.is-active:before {
    background-color: #fff;
    border-color: #17a2b8;
  }
  .multi-steps > li.is-active ~ li {
    color: #808080;
  }
  .multi-steps > li.is-active ~ li:before {
    background-color: #ededed;
    border-color: #ededed;
  }

  .alert > p{
    margin-bottom: 0;    
  }
  
  .recarga-menu{
    color: #4BC0C0;
    font-weight: 500;
    text-transform: uppercase;
  } 

  .nav-link > .recarga-menu {
    color: #4BC0C0;
  } 

  .recarga-menu > .nav-link-active {
    color: #808080 !important;
}


.modal {
  display:    none;
  position:   fixed;
  z-index:    1000;
  top:        0;
  left:       0;
  height:     100%;
  width:      100%;
  background: rgba( 255, 255, 255, .8 ) 
              url("../public/assets/images/ajax-loader.gif")              
              50% 50% 
              no-repeat;              
}

/* When the body has the loading class, we turn
 the scrollbar off with overflow:hidden */
 body.loading {
  overflow: hidden;   
}

/* Anytime the body has the loading class, our
 modal element will be visible */
body.loading .modal {
  display: block;
}

.total_mini{
  font-size: 0.7rem;
}