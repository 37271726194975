.react-date-picker__wrapper {
    border: none !important;        
    color: #898989;
}

.react-date-picker__inputGroup__input {
    color: #898989;
    outline: none;
}

.react-date-picker__calendar-button__icon svg,
.react-date-picker__button__icon svg{
    color: #ccc !important;
    fill: green
}

.react-date-picker__clear-button svg,
.react-date-picker__button svg{
    stroke: rgb(146, 146, 146);    
    fill: green;
}

