.btnVoltar{
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    background-color: rgb(27, 59, 146);
    padding: 10px 20px;
}

.btnVoltar:hover{    
    color: rgb(231, 220, 220);    
}