.container--pgto{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: space-around;
    margin-top: 10px;
}

.container--pgto > div{
    margin-bottom: 10px;
    padding: 20px 30px 3px 30px;
    background-color: #f4f5f7;
    border-radius: 5px;
}

.container--pgto > div hr{
    color: #ccc;
    margin-top: 20px;    
}

.container--pgto img{
    display: inline-block;
    max-height: 28px;
    margin-right: 10px;
    margin-bottom: 24px;
}

.container-valores{
    margin-bottom: 15px;
}

.botao-valor{
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    text-align: center;
    background-color: transparent;
    margin: 0 5px;
    color: #605d5c;
}

.botao-valor:hover{
    transform: scale(1.1); /* Equal to scaleX(0.7) scaleY(0.7) */
    background-color: pink;
    -webkit-transition: -webkit-transform 0.5s ease;
    -moz-transition: -moz-transform 0.5s ease;
    transition: transform 0.3s ease;
}

.botao-valor-azul{
    border: 1px dashed rgb(76, 76, 170);
    background-color: rgb(219, 238, 250);    
}
.botao-valor-verde{
    border: 1px dashed rgb(26, 102, 3);
    background-color: rgb(214, 238, 198);    
}
.botao-valor-laranja{
    border: 1px dashed rgb(236, 157, 9);
    background-color: rgb(238, 221, 198);    
}
.botao-valor-rosa{
    border: 1px dashed rgb(102, 3, 89);
    background-color: rgb(224, 208, 236);    
}
.botao-valor-amarelo{
    border: 1px dashed rgb(135, 138, 2);
    background-color: rgb(238, 237, 198);    
}
.botao-valor-preto{
    border: 1px dashed rgb(218, 221, 217);
    background-color: rgb(94, 95, 93);  
    color: #fff;  
}

.container-final-pgto{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.container-final-pgto > button{
    margin-left: 15px;
    display: block;
    min-width: 190px;
    height: 50px;
    align-self: flex-end;    
}

.container-final-pgto input{
    color: #858585
}


 

/* 
# Tela de pagamento com sucesso 
*/
.congrats-page{
    padding: 0;
    margin: 0;
}

.congrats-container{
    margin: 0 auto;
    color: #fff;
    padding: 3em 1.5em 5.125em;
}

.congrats-sucesso{    
    background-color: rgb(47, 134, 44);    
}

.congrats-erro{    
    background-color:#a42302
}

.congrats-pendente{    
    background-color: #f73;    
}

.congrats--header{   
    margin: -6em auto 1em;
    max-width: 36.25em;
    padding: 0 2em;   
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    text-align: center;     
}

.congrats--header > .icone{
    align-items: center;
    text-align: center;
    background: #fff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    height: 5.625em;
    position: relative;
    width: 5.625em; 
    margin-bottom: 30px; 
}

.congrats--header > .icone > svg{
    font-size: 3rem;
    color: aqua;
    margin: 0 auto;
}

.congrats-badge {
    position: relative;
    width: 300px;
    height: 150px;    
    margin-top: -3em;
    margin-left: -1.5em;
    overflow:hidden;
}

.congrats-badge:after {
  content: "";
  position:absolute;
  margin:-115px;
  width:200px;
  height:205px;
  transform:rotate(45deg);
  background-color:#fdde70;; /* to see where it is */
}

.congrats-corner {
    display: block;
    height: 10em;
    left: 0;    
    text-align: center;
    top: 0;
    padding: 21em 0 0 11.9em;
    position: fixed;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 11.875em;
    z-index: 999;
}

.congrats-corner span {
   display: block;
}

.congrats-badge__title {
    color: #333;
    font-size: 1.125em;
    font-weight: 600;
    line-height: 1.1;
    margin: 26px 0 0 1px;
    position: absolute;
    z-index: 1;
    transform: rotate(-45deg);    
}

.congrats-badge__text {
    color: #009ee3;
    font-size: .65em;
    width: 72px;
    z-index: 1;
    transform: rotate(-45deg);    
    margin: 40px 0 0 8px;
    position: absolute;
}

.icon-warning-badge{
    bottom: 0;
    height: 0.7em;
    position: absolute;
    right: -.3em;
    width: 0.7em;   
    color: #f4f4f4 !important;
}

.congrats--content{
    margin-top: -3.25em;
}

.congrats-text-alert{
    color:rgb(102, 3, 89);
    font-size: 0.7rem;
    margin-top: 2rem;
}

.congrats .ui-card{
    margin: 0 auto 1em;
    padding: 2em;
    width: 36.25em;    
} 

.congrats-content > .group-button {
    margin-top: 2em;
}

.congrats--content h2{
    font-size: 1em;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 1em;
}

.ui-card{
    background-color: #fff;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,.12);
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.12);
    margin: 0 auto 1em;
    padding: 2em;
    width: 36.25em;
}

.ui-button {
    background: #009EE3;
    border-color: #009EE3;
    color: #fff;
    padding: 0.7rem 2rem;
    font-size: 1rem;
    border-radius: 4px;
    text-decoration: none;
}

.ui-button:hover {
    text-decoration: none;
}






// .success, .erro {    
//     min-height: 380px;    
//     background: #fff;    
//     width: 100%;
// }



// .success--header > div,
// .erro--header > div{
//    color: #fff;
//     margin:0 auto;
//    width: 35px;
//    height: 135px;
//    border-radius:100%;
//    background:#fff;
//    text-align:center;
// }

// .success--header > div > i,
// .erro--header > div > i{
//    vertical-align:middle;
//    line-height: 35px;
//    font-size: 1.5rem;
// }

// .success--content a,
// .erro--content a{
//     color: #fff;
//     border-radius: 12px;
//     padding: 5px 10px;
//     transition: all ease-in-out 0.3s;
//     text-decoration: none;
// }

// .success--content a:hover,
// .erro--content a:hover{
//     color:rgb(226, 229, 229)
// }

.comprovante-pgto{
    word-break: break-word;
}

@media (max-width: 700px){
    .botao-valor{
        width: 3.5rem;
        height: 3.5rem;                        
        margin: 0 4px 10px;        
    }

    .container--pgto > div {        
        padding: 15px;        
    }

    .container-final-pgto > button {
        margin-left: 15px;        
        min-width: 185px;
        height: 38px;
        font-size: 0.85rem;        
    }

    .container-final-pgto > button > svg{
        display: none;
    }

    .success, .erro{
        width: 100%;
    }
}

